import Hero from "../components/Hero";
import PlanTrip from "../components/PlanTrip";
import ChooseUs from "../components/ChooseUs";

// import Servicios from "../components/Servicios";
import Faq from "../components/Faq";
import Download from "../components/Download";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Hero />
      <PlanTrip />
      <ChooseUs />
      {/* <Servicios /> */}
      <Faq />
      <Download />
      <Footer />
    </>
  );
}

export default Home;
