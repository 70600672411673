import { Link } from "react-router-dom";
import Logo from "../images/logo/Tramitesseguros-03.png";
import { useState } from "react";

function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <i className="fa-solid fa-xmark"></i>
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <Link onClick={openNav} to="/">
                Inicio
              </Link>
            </li>
            <li className="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {" "}
              <Link className="testi-link" to="/">
                Servicios
              </Link>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              
              <li><Link class="dropdown-item" onClick={openNav} to="/seguros">Seguros</Link></li>
            
            <li><Link class="dropdown-item" onClick={openNav} to="/tramitesmig">Tramites Migratorios</Link></li>
            <li><Link class="dropdown-item" onClick={openNav} to="/NotaryPublic">Notary Public</Link></li>
          </ul>
            </li>
            {/* <li>
              <Link onClick={openNav} to="/Team">
                Quienes Somos
              </Link>
            </li> */}
            {/* <li>
              <Link onClick={openNav} to="/Faq">
                Preguntas Frecuentes
              </Link>
            </li> */}
            <li>
              <Link onClick={openNav} to="/contact">
                Contacto
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/Politic">
                Politicas de Privacidad
              </Link>
            </li>
          </ul>
        </div>

        {/* desktop */}
         
        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div> 
          <ul className="navbar__links">
            <li>
              <Link className="home-link" to="/">
                Inicio
              </Link>
            </li>
            {/* <li>
              {" "}
          
              <Link className="models-link" to="/Faq">
                Preguntas Frecuentes
              </Link>
            </li> */}
            <li className="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="..." id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {" "}
              <Link className="testi-link" to="/servicios">
                Servicios
              </Link>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><Link class="dropdown-item" to="/seguros">Seguros</Link></li>
            
            <li><Link class="dropdown-item"  to="/tramitesmig">Tramites Migratorios</Link></li>
            <li><Link class="dropdown-item" to="/NotaryPublic">Notary Public</Link></li>
            <li><Link class="dropdown-item" to="/Cookies"></Link></li>
          </ul>
            </li>
            {/* <li>
              {" "}
              <Link className="team-link" to="/team">
                Nosotros
              </Link>
            </li> */}
            <li>
              {" "}
              <Link className="contact-link" to="/contact">
                Contacto
              </Link>
            </li>
            <li>
              {" "}
              <Link className="contact-link" to="/Politic">
              Politicas de Privacidad
              </Link>
            </li>
          </ul>
          {/* <div className="navbar__buttons">
            <Link className="navbar__buttons__sign-in" to="/">
              Sign In
            </Link>
            <Link className="navbar__buttons__register" to="/">
              Register
            </Link>
          </div> */}

          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <i className="fa-solid fa-bars">=</i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
