import { useState } from "react";
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"


function Faq() {

  useEffect(()=>{
    Aos.init()
  },[])


  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title" data-aos="fade-down" data-aos-duration="4000">
              <h5>FAQ</h5>
              <h2>Preguntas Frecuentes</h2>
              <p>
                Aqui encontraras algunas de las preguntas mas frecuentas al momento de hacer 
                algun tramite
              </p>
            </div>

            <div className="all-questions" data-aos="fade-up" data-aos-duration="4000">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. Como ser elegible para el Obamacare?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  -Vivir en los Estados Unidos<br/>
                  - Ser ciudadano, o tener un estatus migratorio legal en los Estados Unidos<br/> 
                  - No estar encarcelado<br/>
                  - Que vaya a presentar una declaración de impuestos

                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. Se necesita algun estatus migratorios para los seguros privados individuales?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  No necesitas de un estatus migratorio para odquirir este seguro
                </div>
              </div>
              <div className="faq-box">
                
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. Para que son los Gastos Finales?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div> 
                 <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  Para cubrir los gastos funerarios cuando una persona fallece.
                </div> 
              </div>

              <div className="faq-box">
                
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__question ${getClassQuestion("q4")}`}
                >
                  <p>4. Que Status debe tener para Pedir a un familiar?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div> 
                 <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q4")}`}
                >
                   Este trámite lo pueden hacer ciudadanos o 
                   residentes permanentes legales (LPR) de Estados 
                   Unidos para ayudar a un familiar a convertirse 
                   en residente permanente legal de Estados Unidos 
                   al obtener lo que frecuentemente llaman “Tarjeta Verde”. 
                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
