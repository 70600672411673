import Footer from "../components/Footer";
// import HeroPages from "../components/HeroPages";
import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css"


function Contact() {
  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };
  
  useEffect(()=>{
    Aos.init()
  },[])

  return (
    <>
      <section className="contact-page">
        {/* <HeroPages name="Contact" /> */}
        <div className="container">
          <div className="contact-div" >
            <div className="contact-div__text"data-aos="fade-right" data-aos-duration="4000">
              <h2>¿Necesita alguna Informacion Adicional?</h2>
              <p>
                Contactenos o dejenos su informacion de Contacto
                y le atenderemos a la brevedad para brindarle toda la 
                ayuda posible
              </p>
              <a href="tel:+1 (786)481-9134" target="_blank" rel="noopener noreferrer">
                <i className="fa-solid fa-phone"></i>&nbsp; +1(786)481-9134
              </a>
              <a href="/">
                <i className="fa-solid fa-envelope"></i>&nbsp;
                TramitessegurosUSA@gmail.com
              </a>
              <a href="/">
                <i className="fa-solid fa-location-dot"></i>&nbsp; Miami,
                U.S.A
              </a>
            </div>
            <div className="contact-div__form" data-aos="fade-left" data-aos-duration="4000">
              <form action="https://formsubmit.co/TramitesegurosUSA@gmail.com" method="POST">
                <label>
                  Nombre Completo <b>*</b>
                </label>
                <input type="text" name="Nombre Completo" placeholder='E.g: "Joe Shmoe"'></input>

                <label>
                  Correo <b>*</b>
                </label>
                <input type="email" name="email" placeholder="youremail@example.com"></input>

                <label>
                  Numero Telefonico <b>*</b>
                </label>
                <input type="phone" name="Telefono" placeholder=""></input>

                <label>
                  Hablenos un poco de lo que necesita <b>*</b>
                </label>
                <textarea placeholder="Write Here.." name="Informacion Adicional"></textarea>

                <button type="submit"data-aos="fade-up" data-aos-duration="4000">
                  <i className="fa-solid fa-envelope-open-text"></i>&nbsp; Enviar 
                  Mensaje
                </button>
                
                <input type="hidden" name="_next" value="https://tramitesseguro.com/#/contact"/> 
                <input type="hidden" name="_captcha" value="false"/>
              </form>
              <div className="Politic_form">
            <p>Al enviar este formulario, usted da su consentimiento 
              para recibir mensajes de texto de "Claudia Gomez". 
              La frecuencia puede variar. Pueden aplicarse tarifas por mensajes y datos.
               Responda STOP para dejar de recibir más mensajes o responda HELP para obtener más información. 
               Vea nuestros términos y política de privacidad en nuestra seccion de  <Link onClick={scrollToTop} to="/Politic " >Politicas de Privacidad</Link>
            </p>
           </div>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Llamanos y gestionamos tu tramite</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+1(786)481-9134</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
