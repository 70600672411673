import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Tramitesmig from "../components/tramitesmig";

function TramitesmigPage() {
  return (
    <>
      <section className="tramitesmig-page">
        <HeroPages name="Tramites Migratorios" />
        <Tramitesmig />
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Contactanos y te ayudamos gestionar tus tramites migratorios</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+1(786)481-9134</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default TramitesmigPage;