
import { useState } from "react";
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import Footer from "./Footer";


function Politic() {

  useEffect(()=>{
    Aos.init()
  },[])

 
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="cookies-section">
        <div className="container">
          <div className="faq-content">
            <div className="cookies-content__title" data-aos="fade-down" data-aos-duration="4000">
              
              <h2>Políticas de Privacidad</h2>
             <p>Bienvenido a Trámites Seguros. Esta Política de Privacidad está diseñada para ayudar a comprender 
              cómo recopilamos, usamos, compartimos y salvaguardamos su información personal y relacionada con los seguros. 
              Al utilizar nuestros servicios, usted acepta los términos descritos en esta política. La información móvil no se compartirá, 
              venderá ni transmitirá a terceros con fines de marketing o promoción.</p>
            </div>

            <div className="all-questions" data-aos="fade-up" data-aos-duration="4000">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. Información que recopilamos</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  Recopilamos los siguientes tipos de información:Información 
				  Personal: Nombre, datos de contacto y otros identificadores proporcionados por los usuarios.


                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2.  Cómo utilizamos la información</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
							Utilizamos la información recopilada para los siguientes propósitos:<br/>
								  - Proporcionar y mejorar nuestros servicios.<br/>
								  - Personalización de la experiencia del usuario.<br/>
								  - Comunicarse con los usuarios.<br/>
								  - Analizar el uso y las tendencias del sitio web.<br/>
								  - Cumpliendo requisitos legales y reglamentarios.

                </div>
              </div>
              <div className="faq-box">
                
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3.  Intercambio de información</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
								  Podemos compartir información bajo las siguientes circunstancias:<br />
								  - Con proveedores de servicios externos que ayudan a nuestras operaciones.<br/>
								  - Para el cumplimiento legal y regulatorio.<br/>
								  - Con consentimiento del usuario.

                </div> 
              </div>

              <div className="faq-box">
                
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__question ${getClassQuestion("q4")}`}
                >
                  <p>4. Seguridad de los datos</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q4")}`}
                >
								  Implementamos medidas estándar de la industria para 
								  proteger su información. Sin embargo, ningún método 
								  de transmisión por Internet es completamente seguro.

                </div> 
              </div>
			  <div className="faq-box">
                
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__question ${getClassQuestion("q5")}`}
                >
                  <p>5. Tus Elecciones</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q5"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q5")}`}
                >
								  Los usuarios tienen derecho a controlar
								  ciertos aspectos de la recopilación y el 
								  uso de datos. Esto puede incluir optar 
								  por no recibir comunicaciones promocionales.

                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q6"
                  onClick={() => openQ("q6")}
                  className={`faq-box__question ${getClassQuestion("q6")}`}
                >
                  <p>6. Privacidad de los niños</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q6"
                  onClick={() => openQ("q6")}
                  className={`faq-box__answer ${getClassAnswer("q6")}`}
                >
								  Nuestros servicios no están destinados a 
								  personas menores de 13 años. No recopilamos 
								  conscientemente información personal de niños.


                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q7"
                  onClick={() => openQ("q7")}
                  className={`faq-box__question ${getClassQuestion("q7")}`}
                >
                  <p>7. Información del seguro recopilada</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q7"
                  onClick={() => openQ("q7")}
                  className={`faq-box__answer ${getClassAnswer("q7")}`}
                >
								  Además de la información general mencionada anteriormente, 
								  podemos recopilar detalles específicos relacionados con 
								  la cobertura del seguro, reclamos y beneficiarios. Esto 
								  incluye historial médico, información financiera y otros 
								  datos necesarios para la suscripción,  administración de pólizas y trámites migratorios.


                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q8"
                  onClick={() => openQ("q8")}
                  className={`faq-box__question ${getClassQuestion("q8")}`}
                >
                  <p>8. Uso de la información del Seguro</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q8"
                  onClick={() => openQ("q8")}
                  className={`faq-box__answer ${getClassAnswer("q8")}`}
                >
								  La información recopilada con fines de seguro se utilizará para:<br/>
								 - Evaluación de elegibilidad para la cobertura del seguro. <br/>
								 - Políticas de suscripción y calificación.<br/>
								 - Administrar reclamos y beneficios.<br/>
								 - Cumplir con los requisitos legales y reglamentarios.


                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q9"
                  onClick={() => openQ("q9")}
                  className={`faq-box__question ${getClassQuestion("q9")}`}
                >
                  <p>9. Intercambio de información sobre Seguro</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q9"
                  onClick={() => openQ("q9")}
                  className={`faq-box__answer ${getClassAnswer("q9")}`}
                >
								  Podemos compartir información de seguros en las siguientes circunstancias:<br/>
								  Con compañías de seguros y socios suscriptores.<br/>
								  Para el procesamiento y administración de reclamos.<br/>
								  Para cumplir con los requisitos reglamentarios.<br/>
								  Según lo requieran las autoridades policiales o gubernamentales.



                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q10"
                  onClick={() => openQ("q10")}
                  className={`faq-box__question ${getClassQuestion("q10")}`}
                >
                  <p>10. Consentimiento para información de salud</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q10"
                  onClick={() => openQ("q10")}
                  className={`faq-box__answer ${getClassAnswer("q10")}`}
                >
								 Al proporcionarnos información de salud, 
								 usted acepta la recopilación, el uso y la divulgación 
								 de dicha información para los fines descritos en esta 
								 política.

                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q11"
                  onClick={() => openQ("q11")}
                  className={`faq-box__question ${getClassQuestion("q11")}`}
                >
                  <p>11. Retención de información</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q11"
                  onClick={() => openQ("q11")}
                  className={`faq-box__answer ${getClassAnswer("q11")}`}
                >
								Conservamos información relacionada 
								con seguros durante el tiempo que sea 
								necesario para cumplir con los 
								propósitos descritos en esta política o 
								según lo exijan las leyes y regulaciones 
								aplicables.


                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q12"
                  onClick={() => openQ("q12")}
                  className={`faq-box__question ${getClassQuestion("q12")}`}
                >
                  <p>12. Proveedores de servicios externos</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q12"
                  onClick={() => openQ("q12")}
                  className={`faq-box__answer ${getClassAnswer("q12")}`}
                >
								Podemos contratar proveedores de 
								servicios externos para que nos 
								ayuden con las operaciones relacionadas 
								con los seguros. Estos proveedores 
								están sujetos a acuerdos de 
								confidencialidad y deben cumplir 
								con las leyes de privacidad aplicables.



                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q13"
                  onClick={() => openQ("q13")}
                  className={`faq-box__question ${getClassQuestion("q13")}`}
                >
                  <p>13. Derechos respecto de la información sobre seguros</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q13"
                  onClick={() => openQ("q13")}
                  className={`faq-box__answer ${getClassAnswer("q13")}`}
                >
								Los asegurados y beneficiarios tienen 
								ciertos derechos respecto de la información de sus seguros, 
								incluido el derecho a acceder, rectificar y solicitar la 
								supresión de sus datos. Comuníquese con Trámites seguros 
								para obtener ayuda con estos derechos.




                </div> 
              </div>

			  <div className="faq-box">
                
                <div
                  id="q14"
                  onClick={() => openQ("q14")}
                  className={`faq-box__question ${getClassQuestion("q14")}`}
                >
                  <p>14. Cumplimiento de las leyes de privacidad</p>
                  <i className="fa-solid fa-angle-down">V</i>
                </div> 
                 <div
                  id="q14"
                  onClick={() => openQ("q14")}
                  className={`faq-box__answer ${getClassAnswer("q14")}`}
                >
								Cumplimos con todas las leyes y regulaciones 
								de privacidad relevantes, incluidas, entre otras, 
								para garantizar la protección de su información confidencial.




                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>
	  <Footer/>
    </>
  );
}

export default Politic;