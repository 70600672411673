import HeroPages from "../components/HeroPages";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import img1 from "../images/NotaryPublic/ima1.jpg"
import img2 from "../images/NotaryPublic/ima2.jpg"
import img3 from "../images/NotaryPublic/ima3.jpg"
import img4 from "../images/NotaryPublic/notary2.jpg"
import img5 from "../images/NotaryPublic/notary3.jpg"
import img6 from "../images/NotaryPublic/notary4.jpg"
import img7 from "../images/NotaryPublic/Notary.png"
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"

 

function NotaryPublic(){
  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  useEffect(()=>{
    Aos.init()
  },[])
    return(
        <>
        <HeroPages name="Notary Public" />

       <section className="Notary-main">
       
        <div className="Notary__title" data-aos="fade-right" data-aos-duration="4000">
              
              <h2><font color="#ff1a1a">Notary</font><font color="#0b0842"> Public</font></h2>
              <p>
              “I am not an attorney licensed to 
              practice law in the state of Florida, 
              and I may not give legal advice or accept 
              fees for legal advice.”
              </p>
              <img src={img7} class="img" data-aos="flip-left" data-aos-duration="4000" alt="..."/>
              <div className="btn-notary-1">
              <Link className="btn-cont" onClick={scrollToTop} to="/contact">
                  CONTACTANOS &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </Link>
                </div>
                
                <div className="btn-notary-5">
              {/* <a href="#form_section">
                  SOLICITAR SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a> */}
</div>
            </div>

      <div className="container-card">
<div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col" data-aos="flip-left" data-aos-duration="4000">
    <div class="card">
      <img src={img3} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Matrimonios</h3>
        {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col" data-aos="flip-left" data-aos-duration="4000">
    <div class="card">
      <img src={img2} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Administrar juramentos y afirmaciónes</h3>
        {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col"data-aos="flip-left" data-aos-duration="4000">
    <div class="card">
      <img src={img1} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Toma de reconocimientos</h3>
        {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col"data-aos="flip-left" data-aos-duration="4000">
    <div class="card">
      <img src={img4} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Certificación de fotocopias</h3>
        {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col"data-aos="flip-left" data-aos-duration="4000">
    <div class="card">
      <img src={img5} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Verificar los números de identificación del vehículo</h3>
        {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col"data-aos="flip-left" data-aos-duration="4000">
    <div class="card">
      <img src={img6} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Certificar la apertura de cajas de seguridad</h3>
        {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
        <p class="card-text"></p>
      </div>
    </div>
  </div>

</div>
</div>
</section>

<section id="form_section">

<div className="servicios-content__title">
               
               <h2>Solicita el Servicio</h2>
               <p>
                 LLena el formulario con tus datos y te contactaremos a la brevedad
               </p>
             </div>

             
          <form class="row g-3" action="https://formsubmit.co/TramitesegurosUSA@gmail.com" method="POST">
               <div class="col-md-6">
                 <label for="inputname" class="form-label">Nombre</label>
                 <input type="name" name="Nombre"  class="form-control" id="inputname" />
               </div>
               <div class="col-md-6">
                 <label for="inputlastname" class="form-label">Apellido</label>
                 <input type="lastname" name="Apellido" class="form-control" id="lastname" />
               </div>
               <div class="col-md-6">
                 <label for="inputEmail" class="form-label">Correo</label>
                 <input type="email" name="Email" class="form-control" id="email" />
               </div>
               <div class="col-md-6">
                 <label for="inputAddress" class="form-label">Estado</label>
                 <input type="text" name="Estado" class="form-control" id="inputAddress" placeholder="" />
               </div>
               <div class="col-md-6">
                 <label for="inputAddress2" class="form-label">Ciudad</label>
                 <input type="text" name="Ciudad" class="form-control" id="inputAddress2" placeholder="" />
               </div>
               <div class="col-md-6">
                 <label for="inputCity" class="form-label">Telefono</label>
                 <input type="text" name="Telefono" class="form-control" id="inputCity" />
               </div>
               <div class="col-md-4">
                 <label for="inputState" class="form-label">Servicio Solicitado</label>
                 <select id="inputState" name="Tramite" class="form-select">
                  <option selected>Selecnione un servicio</option>
                   <option  value="Matrimonios">Matrimonios</option>
                   <option   value="Administrar juramentos y afirmaciónes">Administrar juramentos y afirmaciónes</option>
                   <option  value="Toma de reconocimientos" >Toma de reconocimientos</option>
                   <option  value="Certificación de fotocopias" >Certificación de fotocopias</option>
                   <option  value="Verificar los números de identificación del vehículo" >Verificar los números de identificación del vehículo</option>
                   <option  value="Certificar la apertura de cajas de seguridad" >Certificar la apertura de cajas de seguridad</option>
                   

                 </select>
               </div>
               <div class="col-12">
                 <div class="form">
                 </div>
               </div>
               <div class="col-12">
                 <button type="submit" class="btn btn-primary">Enviar</button>
                 <input type="hidden" name="_next" value="https://tramitesseguro.com/#/NotaryPublic"/>
                <input type="hidden" name="_captcha" value="false"/>
               </div>
             </form>
             <div className="Politic_form">
            <p>Al enviar este formulario, usted da su consentimiento 
              para recibir mensajes de texto de "Claudia Gomez". 
              La frecuencia puede variar. Pueden aplicarse tarifas por mensajes y datos.
               Responda STOP para dejar de recibir más mensajes o responda HELP para obtener más información. 
               Vea nuestros términos y política de privacidad en nuestra seccion de  <Link onClick={scrollToTop} to="/Politic " >Politicas de Privacidad</Link>
            </p>
           </div>
      </section>
      <section>
      <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Contactanos y te brindamos el servicio como Notary Public que necesites</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+1(786)481-9134</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />

      </section>
    
        </>
    )
}



export default NotaryPublic;