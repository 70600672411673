import { Link } from "react-router-dom";
// import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/chooseUs/icon1.png";
// import Box2 from "../images/chooseUs/icon2.png";
// import Box3 from "../images/chooseUs/icon3.png";
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"




function ChooseUs() {
  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            {/* <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            /> */}
            <div className="text-container">
              <div className="text-container__left" data-aos="fade-right">
                <h4>Conócenos</h4>
                <h2>Comprometidos en brindar el mejor servicio</h2>
                <p>
                  Somos una empresa de gestion y servicio, con el firme proposito de ayudar,
                  de forma segura y responsable. Tenemos una larga trayectoria en cada uno de los servicios
                  que ofrecemos, brindandole a nuestros clientes la mayor seguridad y respaldo en sus gestiones. 
                </p>
                <Link className="btn-link" onClick={scrollToTop} to="/contact">
                  Contactanos &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </Link>
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box" data-aos="fade-left">
                  <img src={Box1} alt="car-img" />
                  {/* <div className="text-container__right__box__text">
                    <h4>Mision</h4>
                    <p>
                      Take your driving experience to the next level with our
                      top-notch vehicles for your cross-country adventures.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Vision</h4>
                    <p>
                      Get everything you need in one convenient, transparent
                      price with our all-inclusive pricing policy.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>V</h4>
                    <p>
                      Enjoy peace of mind with our no hidden charges policy. We
                      believe in transparent and honest pricing.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
