import { Link } from "react-router-dom";
import Seguros from "../images/plan/iconos.png";
import Contact from "../images/plan/iconos2.png";
import Drive from "../images/plan/iconos3.png";
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"


function PlanTrip() {
  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <>
      <section className="plan-section">
        <div className="container" data-aos="fade-up">
          <div className="plan-container">
            <div className="plan-container__title">
              
              
              <h2>Servicios</h2>
            </div>

            <div className="plan-container__boxes test">
              <div className="plan-container__boxes__box">
              <div className="link">
              <Link className="testi-link"  onClick={scrollToTop} to="/seguros">
                <img src={Seguros} alt="icon_img" className="icon1"/>
                
                  
             
                <h3>Seguros</h3>
                 </Link> 
                </div>
                
                <p>
                
                  Tramitamos diferentes seguros, dentro y fuera de los Estados Unidos
                </p>
              </div>

              <div className="plan-container__boxes__box">
              <div className="link">
              <Link className="testi-link" onClick={scrollToTop} to="/tramitesmig">
                <img src={Contact} alt="icon_img"  className="icon2"/>
                <h3>Tramites Migratorios</h3>
                </Link>
                </div>
                <p>
                  Le ayudamos a gestionar y tramitar cualquier 
                 servicio migratorio que necesite
                </p>
              </div>

              <div className="plan-container__boxes__box">
              <div className="link">
              <Link className="testi-link" onClick={scrollToTop} to="/NotaryPublic">
                <img src={Drive} alt="icon_img" className="icon3" />
                <h3>Notary Public</h3>
                </Link>
                </div>
                <p>
                  Atendemos y realizamos actividades de 
                  la notaria publica en los Estados Unidos
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
