import Img1 from "../images/download/appstore.png";
import Img2 from "../images/download/googleapp.png";

function Download() {
  return (
    <>
      <section className="download-section">
        <div className="container">
          <div className="download-text">
            <h2>Encuéntranos en las redes sociales</h2>
            <p>
              Allí podrás encontrar información al día de las gestiones que necesites
            </p>
            <div className="download-text__btns">
            <a href="https://www.facebook.com/profile.php?id=100093806467780&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"> <img alt="download_img" src={Img2}  /> </a>

             <a href="https://www.instagram.com/tramitesegurosusa/" target="_blank" rel="noopener noreferrer"><img alt="download_img" src={Img1}/></a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Download;
