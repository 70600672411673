import "../src/dist/styles.css";
import Seguros from "./Pages/Seguros";
import Home from "./Pages/Home";
import Navbar from "../src/components/Navbar";
import { Route, Routes } from "react-router-dom";
import ServiciosPage from "./Pages/ServiciosPage";
import Contact from "./Pages/Contact";
import TramitesmigPage from "./Pages/TramitesmigPage";
import NotaryPublic from "./Pages/NotaryPublic";
import Politic from "./components/Politic";


function App() {
  return (
    <>
    
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="seguros" element={<Seguros />} />
        <Route path="servicios" element={<ServiciosPage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="tramitesmig" element={<TramitesmigPage />} />
        <Route path="NotaryPublic" element={<NotaryPublic/>}/>
        <Route path="Politic" element={<Politic/>}/>
      
       
      </Routes>
    </>
  );
}

export default App;
