// import Img2 from "../images/servicios/obamacare6.jpg";
// import Img3 from "../images/servicios/pfp2.jpg";


function Servicios() {
  return (
    <>
      <section className="servicios-section">
        <div className="container">
          <div className="servicios-content">
            <div className="servicios-content__title">
              
              <h2>Seguros</h2>
              <p>
                Discover the positive impact we've made on the our clients by
                reading through their testimonials. Our clients have experienced
                our service and results, and they're eager to share their
                positive experiences with you.
              </p>
            </div>
            


             <div className="all-servicios">
              <div className="all-servicios__box">
                  <i className="fa-solid"></i>
                <h1>Obamacare</h1>
                <p>
                Son seguros de salud a bajo costo. Servicios preventivos. Cubren exámenes anuales de bienestar sin costo. 
                Vacunas sin costo. Laboratorios, Recetas médicas, Emergencia, hospitalización, 
                embarazo, cirugías y más!!
                </p>
                <div className="all-servicios__box__name">
                  <div className="all-servicios__box__name__profile">
                    {/* <img src={Img2} alt="user_img" /> */}
                    
                  </div>
                </div>
              </div> 

               <div className="all-servicios__box box-2">
                  <i className="fa-solid"></i>
                  <h1>Obamacare</h1>
                <p>
                  "The car was in great condition and made our trip even better.
                  Highly recommend for this car rental website!"
                </p>
                <div className="all-servicios__box__name">
                  <div className="all-servicios__box__name__profile">
                    {/* <img src={Img3} alt="user_img" /> */}
                    
                  </div>
                </div>
              </div> 
              <div className="all-servicios__box">
                  <i className="fa-solid"></i>
                <h1>Obamacare</h1>
                <p>
                  "We rented a car from this website and had an amazing
                  experience! The booking was easy and the rental rates were
                  very affordable. "
                </p>
                <div className="all-servicios__box__name">
                  <div className="all-servicios__box__name__profile">
                    {/* <img src={Img2} alt="user_img" /> */}
                    
                  </div>
                </div>
              </div> 
              </div> 

            <form class="row g-3" action="https://formsubmit.co/brayhamdiaz@gmail.com" method="POST">
             
            <div className="servicios-content__title">
              
              <h2>Solicita el Servicio</h2>
              <p>
                LLena el formulario con tus datos y te contactaremos a la brevedad
              </p>
            </div>
              <div class="col-md-6">
                <label for="inputname" class="form-label">Nombre</label>
                <input type="name" class="form-control" id="inputname" />
              </div>
              <div class="col-md-6">
                <label for="inputPassword4" class="form-label">Apellido</label>
                <input type="password" class="form-control" id="inputPassword4" />
              </div>
              <div class="col-md-6">
                <label for="inputPassword4" class="form-label">Correo</label>
                <input type="password" class="form-control" id="inputPassword4" />
              </div>
              <div class="col-md-6">
                <label for="inputAddress" class="form-label">Estado</label>
                <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" />
              </div>
              <div class="col-md-6">
                <label for="inputAddress2" class="form-label">Ciudad</label>
                <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />
              </div>
              <div class="col-md-6">
                <label for="inputCity" class="form-label">Telefono</label>
                <input type="text" class="form-control" id="inputCity" />
              </div>
              <div class="col-md-4">
                <label for="inputState" class="form-label">Servicio Solicitado</label>
                <select id="inputState" class="form-select">
                  <option selected>Seguros / Obamacatre</option>
                  <option>...</option>
                </select>
              </div>
              <div class="col-12">
                <div class="form">
                </div>
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-primary">Enviar</button>
              </div>
            </form>
          </div>
        </div>

          
         
      
      </section>
    </>
  );
}

export default Servicios;
